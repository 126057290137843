require("./bootstrap");

$(document).ready(function () {
    /* INCLUDE SVG */
    $('img[data-src$=".svg"]').each(function () {
        var $img = jQuery(this);
        var imgURL = $img.attr("data-src");
        var attributes = $img.prop("attributes");

        $.get(
            imgURL,
            function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find("svg");

                // Remove any invalid XML tags
                $svg = $svg.removeAttr("xmlns:a");

                // Loop through IMG attributes and apply on SVG
                $.each(attributes, function () {
                    $svg.attr(this.name, this.value);
                });

                // Replace IMG with SVG
                $img.replaceWith($svg);
            },
            "xml"
        );
    });

    /* SCROLL TO FUNCTION */

    /*
  
  $('.js-scrollTo').on('click', function() {
    var page = $(this).attr('data-href');
    var speed = 750;
    
    $('html, body').animate( { scrollTop: $(page).offset().top - 250}, speed);
    
    return false;
  });
  
  */

    // SCROLLto
    function scrollTo(target) {
        var speed = 750;
        var windowWidth = $(window).width();

        if (windowWidth > 995) {
            $("html, body").animate(
                { scrollTop: $(target).offset().top - 0 },
                speed
            );
        } else {
            $("html, body").animate(
                { scrollTop: $(target).offset().top - 100 },
                speed
            );
        }

        return false;
    }

    $(".js-scrollTo").on("click", function (e) {
        e.preventDefault();

        var page = $(this).attr("href");
        var target = page.substr(page.indexOf("#"));

        if (document.querySelector(target) != null) {
            scrollTo(target);
        } else {
            window.location = page + "?scroll=1";
        }
    });
    
    // UPDATE THE CURRENT SLIDE INDICATOR 
    $('.custom-carousel').on('slide.bs.carousel', function (event) {
        let newSlide = event.to;
        
        this.querySelector('.current-slide').textContent = newSlide + 1
    })
    
});

/* COUNTDOWN */

$("#stockAccordion").on("shown.bs.collapse", function (event) {
    document
        .querySelectorAll(".trigger[aria-expanded]")
        .forEach(function (item) {
            if (item.getAttribute("aria-expanded") == "true") {
                $("html, body").animate(
                    {
                        scrollTop:
                            $("#" + item.parentElement.id).offset().top - 100,
                    },
                    500
                );
            }
        });
});

function countdownTimer() {
    var el = document.getElementById("countdown");
    if (!el) {
        return false;
    }
    var end = moment(el.getAttribute("data-end"));
    var now = moment();
    var difference = end - now;

    var remaining = "";

    if (difference > 0) {
        var parts = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    } else {
        var parts = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
        clearInterval(counterInterval);

        document.getElementById("countdown-container").style.display = "none";
        document.getElementById("take-rdv").style.marginTop = "40px";
        var signatureBtnEls = document.querySelectorAll(".cta-signature");

        for (var i = 0; i < signatureBtnEls.length; i++) {
            signatureBtnEls[i].classList.remove("d-none");
        }
    }

    var days = el.querySelector("#days"),
        hours = el.querySelector("#hours"),
        minutes = el.querySelector("#minutes"),
        seconds = el.querySelector("#seconds");

    days.textContent = parts.days;
    hours.textContent = parts.hours;
    minutes.textContent = parts.minutes;
    seconds.textContent =
        parts.seconds < 10 ? "0" + parts.seconds : parts.seconds;
}

countdownTimer();
var counterInterval = setInterval(countdownTimer, 1000);

/* LOTS SELECTION */
window.selectedLots = [];
window.selectedSlot = null;
window.event = "demande-infos";
window.globalEvent = "decouverte";
window.rdvType = "";

window.maxLotsPerType = {
    signature: 1,
    decouverte: 2,
};
window.maxLots = {
    decouverte: 2,
    signature: 1,
};

var counter = 0;

window.selectLot = function (type, IdLot) {
    var footer = document.getElementById("footer");
    var rendezVousSticky = document.getElementById("rendez-vous-sticky");

    if (window.selectedLots.indexOf(IdLot) >= 0) {
        window.selectedLots.splice(window.selectedLots.indexOf(IdLot), 1);

        var unSelectedBtn = document.querySelectorAll(
            '[data-event="' + type + "-" + IdLot + '"].selected'
        );

        for (var i = 0; i < unSelectedBtn.length; i++) {
            unSelectedBtn[i].classList.remove("selected");
        }

        counter--;
    } else {
        if (counter < window.maxLots[type]) {
            window.selectedLots.push(IdLot);

            var selectedBtn = document.querySelectorAll(
                '[data-event="' + type + "-" + IdLot + '"]'
            );

            for (var i = 0; i < selectedBtn.length; i++) {
                selectedBtn[i].classList.add("selected");
            }

            counter++;
        }
    }

    if (counter > 0) {
        footer.classList.add("in-selection");
        rendezVousSticky.classList.add("in-selection");
        rendezVousSticky.classList.add("is-sticky");
        document.querySelector(".lots-count").textContent = counter;
        document.querySelector('.actions .rdv').setAttribute("data-disabled", false);
        rendezVousSticky
            .querySelector(".btn")
            .setAttribute("data-disabled", false);
    } else {
        footer.classList.remove("in-selection");
        rendezVousSticky.classList.remove("in-selection");
        document.querySelector('.actions .rdv').setAttribute("data-disabled", true);
        rendezVousSticky
            .querySelector(".btn")
            .setAttribute("data-disabled", true);
    }

    if (counter === window.maxLots[type]) {
        var ctaDisabled = document.querySelectorAll(".btn-cta:not(.selected)");

        for (var i = 0; i < ctaDisabled.length; i++) {
            ctaDisabled[i].setAttribute("disabled", "disabled");
            ctaDisabled[i].setAttribute("data-balloon-pos", "up");
        }
    } else {
        var ctaDisabled = document.querySelectorAll(
            ".btn-cta:disabled:not(.not-available)"
        );

        for (var i = 0; i < ctaDisabled.length; i++) {
            ctaDisabled[i].removeAttribute("disabled");
            ctaDisabled[i].removeAttribute("data-balloon-pos");
        }

        if (type == "decouverte") {
            var ctaDisabled = document.querySelectorAll(".cta-signature");

            for (var i = 0; i < ctaDisabled.length; i++) {
                ctaDisabled[i].setAttribute("disabled", "disabled");
                ctaDisabled[i].setAttribute("data-balloon-pos", "up");
            }
        }
    }

    if (counter > 0) {
        window.event = type;

        var btnSlots = document.querySelectorAll(".btn-slot-time");

        for (var i = 0; i < btnSlots.length; i++) {
            btnSlots[i].setAttribute("data-type", type);
        }
    } else {
        var ctaDisabled = document.querySelectorAll(
            ".btn-cta:disabled:not(.not-available)"
        );

        for (var i = 0; i < ctaDisabled.length; i++) {
            ctaDisabled[i].removeAttribute("disabled");
            ctaDisabled[i].removeAttribute("data-balloon-pos");
        }

        window.event = "demande-infos";
    }

    window.globalEvent = type;
};

// TOGGLE SRC ON VIDEO MODAL OPEN
$("#modal-video").on("show.bs.modal", function (event) {
    var button = $(event.relatedTarget);
    var src = button.data("url");
    var modal = $(this);
    modal.find("iframe").attr("src", src);
});

$("#modal-video").on("hidden.bs.modal", function (event) {
    var modal = $(this);
    modal.find("iframe").attr("src", "");
});

/* ON MODAL OPEN, SET LOT INFOS ON FORM */
$("#modal-register").on("shown.bs.modal", function () {
    document
        .getElementById("selected-lots")
        .querySelector(
            "span#selected-text"
        ).textContent = window.selectedLots.join(", ");

    formEl.querySelector('[name="lots"]').value = window.selectedLots.join(
        ", "
    );

    if (window.selectedSlot) {
        
        formEl.querySelector('[name="slot"]').value = window.selectedSlot;
    }
    
    formEl.querySelector('[name="event"]').value = window.event;
    formEl.querySelector('[name="global_event"]').value = window.globalEvent;

    var selectedEls = document.querySelectorAll(".btn-cta.selected");
    var typologies = [];
var typologiesEl = formEl.querySelector("#typologies");

    typologiesEl.innerHTML = "";

    for (var i = 0; i < selectedEls.length; i++) {
        var typologie = selectedEls[i].getAttribute("data-typology");

        if (typologies.indexOf(typologie) < 0) {
            typologies.push(typologie);

            var input = document.createElement("INPUT");
            input.name = "typologies[]";
            input.type = "hidden";
            input.value = typologie;

            typologiesEl.appendChild(input);
        }
    }
});

var formEl = document.querySelector(".form-rdv");
var errorsEl = formEl.querySelector("#errors");


/* SWITCH STEP */
window.changeStep = function (step) {
    var el = document.getElementById("register-steps");

    el.setAttribute("data-step", step);

    if (step === 2) {
        document.querySelector("#register-steps").scrollIntoView(true);
        clearErrors(formEl, errorsEl);
    }

    if (step === 3) {
        // document.querySelector("#modal-register .modal-content").style.height =
        //     "550px";

        if (window.innerWidth < 1200) {
            
            var el =  document
                .querySelector("#register-steps .grid-item-3 .close");
                
            if(el != null) {
                document
                    .querySelector("#register-steps .grid-item-3 .close")
                    .scrollIntoView(true);
                
            }
        }
    }
    if (step == 4) {
        if (window.event === "signature") {
            document
                .querySelector(".appointment-ml span")
                .classList.remove("d-none");
        }

        var confVisio = document.getElementById("visio-only");
        if (window.rdvType == "bv") {
            confVisio.classList.add("d-none");
        } else {
            confVisio.classList.remove("d-none");
        }
    }
};

/* SET EVENT TYPE, */
window.changeEventType = function (type) {
    window.event = type;
    
    formEl.querySelector('[name="event"]').value = window.event;
};

/* SELECTED SLOT IN CALENDAR */
window.selectSlot = function (slot, event) {
    window.selectedSlot = slot;
    window.event = event.target.getAttribute("data-type");
    window.changeEventType(window.event);
    window.changeStep(3);

    formEl.querySelector('[name="slot"]').value = window.selectedSlot;
};

/* DISPLAY PREV / NEXT SLOTS */
window.changeSlot = function (action) {
    var el = document.getElementById("slots");
    var currentSlot = parseInt(el.getAttribute("data-slot"));
    var maxSlots = parseInt(el.getAttribute("data-slots"));
    var width = 152;

    if (action === "next" && currentSlot < maxSlots) {
        currentSlot++;
    } else if (action === "prev" && currentSlot >= 2) {
        currentSlot--;
    } else {
        return false;
    }

    el.style.transform = "translateX(-" + width * (currentSlot - 1) + "px)";

    el.setAttribute("data-slot", currentSlot);
};

/* SELECT SLOT */
window.toggleSlots = function (event) {
    var el = document.getElementById("slots");
    var btn = document.getElementsByClassName("toggle-slots")[0];

    if (el.classList.contains("is-closed")) {
        el.classList.remove("is-closed");

        btn.children[0].textContent = "moins";
    } else {
        el.classList.add("is-closed");

        btn.children[0].textContent = "plus";
    }
};

/* SHOW OR HIDE MORE SLOTS */
window.loadMoreSlots = function (event) {
    var cardBody = event.target.parentElement.parentElement.querySelector(
        ".card-body ul"
    );

    cardBody.classList.toggle("open");

    if (event.target.dataset.collapse == "true") {
        event.target.dataset.collapse = "false";
    } else {
        event.target.dataset.collapse = "true";
    }
};

function openModal(event) {
    
    if (event.target.getAttribute("data-disabled") === "true") {
        $("html, body").animate(
            {
                scrollTop:
                    $(event.target.getAttribute("href")).offset().top - 250,
            },
            750
        );
        event.stopImmediatePropagation();
    }
}

window.refreshSlots = function (rdvType) {
    window.rdvType = rdvType;

    formEl.querySelector('[name="rdv_type"]').value = rdvType;

    $.get("slots?type=" + rdvType).done(function (response) {
        var slotsEl = document.getElementById("slots");

        // Empty slots
        slotsEl.innerHTML = "";

        // Update slots length
        slotsEl.setAttribute("data-slots", response.days);
        slotsEl.setAttribute("data-slot", 1);

        var daysLen = 0;

        for (var day in response.slots) {
            daysLen++;

            var gridSlot = document.createElement("DIV");
            gridSlot.classList = "grid-slot card";
            gridSlot.classList.add("grid-slot-" + daysLen);

            var gridSlotTpl = '<div class="card-header">';
            gridSlotTpl +=
                '<h5 type="button" class="go-eb" data-toggle="collapse" data-target="#slot-group-' +
                daysLen +
                '" aria-expanded="' +
                (daysLen === 1 ? true : false) +
                '" aria-controls="slot-group-' +
                daysLen +
                '">';
            gridSlotTpl += moment(day).format("dddd DD/MM");
            gridSlotTpl +=
                '<span class="carret d-block transition-bezier d-xl-none"></span>';
            gridSlotTpl += "</h5>";
            gridSlotTpl += "</div>";
            gridSlotTpl +=
                '<div class="slot collapse ' +
                (daysLen === 1 ? "show" : "") +
                '" id="slot-group-' +
                daysLen +
                '" aria-labelledby="headingOne" data-parent="#slots">';
            gridSlotTpl += '<div class="card-body">';
            gridSlotTpl +=
                '<ul class"d-flex justify-content-between justify-content-xl-center flex-wrap day-slot" data-day="' +
                day +
                '">';
            for (var time in response.slots[day]) {
                if (response.slots[day][time] > 0) {
                    gridSlotTpl += '<li data-time="' + time + '">';
                    gridSlotTpl += `<button data-available_slot="${response.slots[day][time]}" data-selectable-slot="${day} ${time}" class="btn btn-sm btn-slot-time" onclick="window.selectSlot('${day} ${time}', event);" data-type="${window.event}">`;
                    gridSlotTpl += time;
                    gridSlotTpl += "</button>";
                    gridSlotTpl += "</li>";
                }
            }
            gridSlotTpl += "</ul>";
            gridSlotTpl += "</div>";
            gridSlotTpl += "</div>";

            gridSlot.innerHTML = gridSlotTpl;
            slotsEl.append(gridSlot);
        }

        if (window.event == "signature") {
            document.getElementById("decouverte-only").classList.add("d-none");
            document
                .getElementById("signature-only")
                .classList.remove("d-none");

            // PRESET FIRST SLOT FOR SIGNATURE
            var firstSlot = document.querySelector("#slot-group-1 button")
                .dataset.selectableSlot;

            var presetSlot = {
                day: moment(firstSlot).format("dddd DD/MM"),
                time: moment(firstSlot).format("H:mm"),
            };

            window.event = "signature";
            window.changeEventType(window.event);
            //window.changeStep(3);

            document.getElementById("auto-day").textContent = presetSlot.day;
            document.getElementById("auto-time").textContent = presetSlot.time;
            formEl.querySelector('[name="slot"]').value = firstSlot;

        } else if (window.event == "decouverte") {
            document
                .getElementById("decouverte-only")
                .classList.remove("d-none");
            document.getElementById("signature-only").classList.add("d-none");
        }
    });
};

/* SET ERROR ON FORM */

window.setErrors = function (formEl, errorsEl, errors) {
    var errorsCount = 0;
    var errorsContent = "<ul>";

    for (var key in errors) {
        if (
            key === "slot" ||
            (key === "email" &&
                errors[key][0] ===
                    "Vous avez déjà un rendez-vous avec un de nos conseillers. Celui-ci va prendre contact avec vous dans les plus brefs délais pour confirmer avec vous l’heure de votre rendez-vous.")
        ) {
            if(window.event != 'demande-infos') {
                document.querySelector(
                    '[data-selectable-slot="' + window.selectedSlot + '"]'
                ).parentElement.style.display = "none";
                
            }

            errorsContent += "<li>" + errors[key][0] + "</li>";

            errorsCount++;
        }

        var el = formEl.querySelector('[name="' + key + '"]');

        if (key === "telephone") {
            el.parentNode.classList.add("has-error");
        } else {
            el.classList.add("has-error");
        }
    }
    errorsContent += "</ul>";

    errorsEl.innerHTML = errorsContent;

    if (errorsCount > 0) {
        errorsEl.classList.remove("d-none");
    }
};

/* RESET LAYOUT */
function resetLayout(formEl) {
    var ctaSelected = document.querySelectorAll(".btn-cta.selected");

    for (var i = 0; i < ctaSelected.length; i++) {
        ctaSelected[i].classList.remove("selected");
    }

    var ctaDisabled = document.querySelectorAll(
        ".btn-cta:disabled:not(.cta-signature)"
    );

    for (var i = 0; i < ctaDisabled.length; i++) {
        ctaDisabled[i].removeAttribute("disabled");
    }

    var rendezVousSticky = document.getElementById("rendez-vous-sticky");

    rendezVousSticky.classList.remove("in-selection");
    rendezVousSticky.querySelector(".btn").setAttribute("data-disabled", true);

    // var collapsedTable = document.querySelectorAll('.table-lots .trigger.active');

    // for(var i = 0; i < collapsedTable.length; i ++) {

    //   collapsedTable[i].classList.remove('active');
    // }
}

/* CLEAR FORM ERRORS*/
function clearErrors(formEl, errorsEl) {
    var errors = formEl.querySelectorAll(".has-error");

    for (var i = 0; i < errors.length; i++) {
        errors[i].classList.remove("has-error");
    }

    errorsEl.innerHTML = "";
    errorsEl.classList.add("d-none");
}

/* SUBMIT FORM */
window.submitForm = function (event, target) {
    var form = $(target);
    errorsEl = target.querySelector('#errors');
    var inputs = form.serialize();
    var url = form.attr("action");
    var formEl = target;
    var btnEl = formEl.querySelector('button[type="submit"]');
    var confMl = document.querySelector(".appointment-ml");
    var confRdv = document.querySelector("#rdv-only");
    var confInfos = document.querySelector("#infos-only");
    btnEl.setAttribute("disabled", "disabled");
    btnEl.textContent = "Patientez ...";

    clearErrors(formEl, errorsEl);
    $.post(url, inputs)
        .done(function (data, statusText, xhr) {
            if (xhr.status === 200) {
                btnEl.removeAttribute("disabled");

                if (data.success) {
                    document.getElementById(
                        "appointment-lots"
                    ).textContent = window.selectedLots.join(", ");

                    if (inputs.event == "signature") {
                        confMl.style.display = "block";
                    } else {
                        confMl.style.display = "none";
                    }
                    if(window.event == "demande-infos") {
                        confRdv.style.display = 'none';
                    }
                    else {
                        confInfos.style.display = 'none';
                    }

                    btnEl.textContent = "Valider";
                    
                    if(formEl.classList.contains('form-col')) {
                       $('#modal-register').modal('show');
                    }
                    changeStep(4);

                    resetLayout(formEl);

                }

                if (data.appointment) {
                    document.getElementById("appointment-date").textContent =
                        data.start_date;
                    document.getElementById("appointment-time").textContent =
                        data.start_time + "h" + data.start_minutes;
                } else {
                    document.getElementById(
                        "appointment-date"
                    ).parentElement.style.display = "none";
                }
                
                function gtag_report_conversion(url) {
                    var callback = function () {
                        if (typeof url != "undefined") {
                            window.location = url;
                        }
                    };
                    gtag("event", "conversion", {
                        send_to: "AW-431241472/yLVdCKWqkfQBEIDy0M0B",
                        event_callback: callback,
                    });
                    return false;
                }

                gtag_report_conversion();
                fbq('track', 'CompleteRegistration');
                
                $("body").append(
                        "<script type='text/javascript'>trackAdvcb = new Array();trackAdvcb.typeT = 'c';</script><script src='//www.advcredirect.com/tadvc-v1b.js'></script>"
                    );

            }
        })
        .fail(function (error) {
            var errors = {};

            btnEl.removeAttribute("disabled");
            btnEl.textContent = "Valider";

            if (error.status == 422) {
                errors = error.responseJSON.errors;

                if (Object.keys(errors).length > 0) {
                    window.setErrors(formEl, errorsEl, errors);
                }
            }
        });

    event.preventDefault();
};


var banner  = document.querySelector('#banner-top');
var asideHeader = document.querySelector('#aside-header');
var asideBody = document.querySelector('#aside-body');
var movies = document.querySelector('.movies');
var actions =  document.querySelector('.actions');

window.addEventListener('scroll', function(event) {
	var bannerPosition = banner.getBoundingClientRect();
    var asideBodyPosition = asideBody.getBoundingClientRect();
    var moviesPosition = movies.getBoundingClientRect();
    var rdvCount = document.querySelector('#rendez-vous-sticky');
    var device = 'desktop';
    var scrollPosition = this.scrollY;
    
    if(window.innerWidth < 767) {
        device = 'mobile';
    }
    if (window.innerWidth >= 768 && window.innerWidth < 1280) {
        device = 'tablet';
    }
    if(device == 'mobile') {
    	// checking for partial visibility
    	if((scrollPosition < 110) || (asideBodyPosition.top < window.innerHeight && asideBodyPosition.bottom >= 0)) {
    		actions.style.position = 'static'
    		actions.classList.add('d-none')
    		rdvCount.classList.add('d-none')
    	}
    	else {
    	    console.log('non visible');
    	    actions.style.position = 'fixed'
    	    actions.classList.remove('d-none')
    	    rdvCount.classList.remove('d-none')
    	}
        
    }
    
    if(device == 'tablet') {
        console.log(moviesPosition.bottom )
        if((scrollPosition < 110) || (moviesPosition.bottom < window.innerHeight - 80 )) {
    		//actions.style.position = 'static'
    		asideHeader.style.position = 'static'
    		actions.classList.add('d-none')
    		rdvCount.classList.add('d-none')
    	}
    	else {
    	    console.log('non visible');
    	    //actions.style.position = 'fixed';
    	    asideHeader.style.position = 'fixed'
    	    rdvCount.style.position = 'fixed';
    	    actions.classList.remove('d-none')
    	    rdvCount.classList.remove('d-none')
    	}
        
    }
	// checking whether fully visible
// 	if(position.top >= 0 && position.bottom <= window.innerHeight) {
// 		console.log('Element is fully visible in screen');
// 	}

});